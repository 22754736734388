/* eslint-disable react/jsx-handler-names */
import PropTypes from "prop-types";
import React, { useState } from "react";
import CustomImage from "../../../atoms/customImage";
import MenuItems from "./menuItems";
import { cleverTapCommonEvents } from "../../../../analytics/clevertapConstants";
import useAnalytics from "../../../../analytics/useAnalytics";
import { pageName } from "../../../../utils/common";
import { usePathname } from "next/navigation";
import { shallowEqual, useSelector } from "react-redux";

/**
 * MegaMenu Component
 * @param {Object} props - The props object
 * @param {Array} props.menuItems - Array of menu items
 * @returns {JSX.Element|null} - Returns the MegaMenu JSX or null if no menu items provided
 */
const MegaMenu = ({ menuItems = [] }) => {
  const { trackEvent } = useAnalytics();
  const [showMenu, setShowMenu] = useState(null);
  const pathname = usePathname();
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const { product } = useSelector(
    (state) => ({
      product: state?.pdp?.clientCurrentClickedItem,
    }),
    shallowEqual,
  );
  const urlIdentifier = product?.urlIdentifier;

  const megaMenuData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    action_type: "menu click",
  };

  if (!menuItems || menuItems.length === 0) {
    return null;
  }

  /**
   * Handles mouse enter event on menu category
   * @param {string} categoryName - The name of the category
   */
  const onMouseEnter = (categoryName) => {
    setShowMenu(categoryName);
  };

  /**
   *
   * Handles mouse leave event on menu
   */
  const onMouseLeave = () => {
    setShowMenu(null);
  };

  return menuItems?.map((category) =>
    category?.childCategoryList?.length > 0 ? (
      <nav
        key={`${category.categoryName}${category.parentProductCategoryId}`}
        className="relative z-50 flex py-2 before:absolute before:-bottom-12 before:z-1 before:h-[2px] before:w-full before:content-[''] hover:before:bg-green-100"
        onMouseLeave={onMouseLeave}
        onMouseEnter={() => onMouseEnter(category.categoryName)}
        role="nav_menu_items"
        id="nav_menu_items"
      >
        <span className="inline-block cursor-pointer overflow-visible whitespace-nowrap font-inter text-12 text-grey-300 xl:text-14">
          <a
            href={`/${category.productCategoryId}`}
            className="flex items-center justify-center gap-4"
            role="button"
            aria-label={category.categoryName}
            onClick={() => {
              trackEvent(
                cleverTapCommonEvents.megaMenuClicked,
                {
                  ...megaMenuData,
                  menu_name: category?.categoryName,
                  menu_category: category?.productCategoryId,
                  image_name: category?.imageSource, // Need to check with Priyanshu, if required here or not?
                  image_id: category?.imageSource, // Need to check with Priyanshu, if required here or not?
                  is_having_image: !!category?.banners?.length,
                },
                ["clevertap"],
              );
            }}
          >
            <span
              className={`text-12 font-500 text-black-900 hover:text-green-100 xxl:text-14 ${category?.dataStyle ? category?.dataStyle : ""}`}
            >
              {category.categoryName}
            </span>
            <span
              className={`h-10 w-10 transition-all duration-500 ${showMenu === category.categoryName ? "rotate-180" : "rotate-360"}`}
            >
              <CustomImage src="/icons/navigation-chevron.svg" alt="down-arrow" height={100} width={100} sizes="10vw" />
            </span>
          </a>
        </span>
        <div className="fixed left-0 right-0 mt-[33px] xxl:mt-34">
          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out 
            ${showMenu === category.categoryName ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}
          >
            {
              <MenuItems
                category={category?.childCategoryList}
                imageBanners={category?.banners}
                productCategoryId={category?.productCategoryId}
              />
            }
          </div>
        </div>
      </nav>
    ) : null,
  );
};

MegaMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      categoryName: PropTypes.string,
      productCategoryId: PropTypes.string,
      childCategoryList: PropTypes.array,
    }).isRequired || PropTypes.array,
  ).isRequired,
};

export default React.memo(MegaMenu);
