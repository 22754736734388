import React from "react";
import PropTypes from "prop-types";
import CustomImage from "../../../atoms/customImage";
import { COUNTRIES, COUNTRIES_URL } from "../../../../constants/common/common";
import { GLOBAL, INTERNATIONAL, NEW, OTHER_COUNTRIES } from "../../../../constants/common/headerToolbarConstants";

/**
 * Renders the child menu items based on the provided category data.
 * @param {Object} props - Component props.
 * @param {Array} props.category - Array of category data.
 * @returns {JSX.Element|null} - JSX representing the child menu items or null if category is not provided.
 */
const MenuItems = ({ category = [], imageBanners = [], productCategoryId = "" }) => {
  if (!Array.isArray(category) || category.length === 0) {
    return null;
  }

  /**
   * Render sub children category.
   * @param {*} childItem
   * @returns
   */
  const subChildrenCategory = (childItem) => {
    return childItem?.map((childItems, index) => {
      return (
        <a
          key={`sub-${childItems.productCategoryId}-${index}`}
          id={childItems.parentProductCategoryId}
          href={`${childItems.href}`}
        >
          <div className="inter flex gap-2 text-14 font-400 leading-30 text-grey-500">
            <div className="relative block w-fit after:absolute after:block after:h-1 after:w-full after:origin-left after:scale-x-0 after:bg-fnp-300 after:transition after:duration-300 after:content-[''] hover:text-fnp-300 after:hover:scale-x-100">
              {childItems.categoryName}
            </div>
            {childItems?.isNew && (
              <div>
                <span className="text-white ml-10 rounded-full bg-red-500 px-8 py-4 text-12 text-white-900">{NEW}</span>
              </div>
            )}
          </div>
        </a>
      );
    });
  };

  /**
   * Renders child items within the same column.
   * @param {Array} childItem - Array of child items.
   * @returns {Array<JSX.Element>} - Array of JSX elements representing child items within the same column.
   */
  const renderSameColumn = (childItem) =>
    childItem.map((item, index) => {
      return (
        <React.Fragment key={`same-column-${item.productCategoryId}-${index}`}>
          {index > 0 && <span className="my-16 w-full border-b border-gray-300" />}
          <div id="same-column-category" key={`column-${item.productCategoryId}-${index}`} className="grid gap-2">
            <span className="text-12 font-semibold text-fnp-500 duration-200 hover:text-fnp-300 xl:text-14">
              {item.categoryName}
            </span>
            <div className="grid gap-2">{subChildrenCategory(item.childCategoryList)}</div>
          </div>
        </React.Fragment>
      );
    });

  /**
   * Function to render the image column.
   * @param {Object} item - The item data containing image source and product category id.
   * @param {string} bgColor - Background color for the image column.
   * @returns {JSX.Element} - JSX element representing the image column.
   */
  const imageColumn = (item, bgColor) => (
    <div id="image-column" className={`relative flex h-full w-full items-center justify-center p-2.5 ${bgColor}`}>
      <CustomImage
        src={item.imageUrl} // For local - need to add the UAT domain to get the image.
        width={250}
        sizes="10vw"
        height={250}
        alt={item.imageAlt}
      />
    </div>
  );

  /**
   * TO get the link for Global.
   * @param {*} country
   * @returns
   */
  const getGlobalLink = (country) => {
    if (COUNTRIES.SINGAPORE === country?.toLowerCase()) {
      return COUNTRIES_URL.SINGAPORE.url;
    } else {
      return `/${country.toLowerCase()}/gifts-lp`;
    }
  };

  /**
   * To render category items.
   * @param {*} item
   * @param {*} bgColor
   * @param {*} index
   * @param {*} isImage
   * @returns
   */
  const renderItems = (item, bgColor, index, isImage) => (
    <div
      id="all-children-category-items"
      key={`category-${item.productCategoryId}-${index}`}
      className={`flex flex-col items-start gap-2 p-16 ${bgColor} ${!isImage ? "flex-grow" : ""}`}
    >
      {isImage ? (
        <a href={`/${productCategoryId}`}>
          <span>{imageColumn(item, bgColor)}</span>
        </a>
      ) : (
        <React.Fragment key={item.productCategoryId}>
          {Array.isArray(item) && renderSameColumn(item)}
          {typeof item === "object" && (
            <>
              {(item.parentProductCategoryId === GLOBAL || item.parentProductCategoryId === INTERNATIONAL) &&
              item.productCategoryId?.toLowerCase() !== OTHER_COUNTRIES ? (
                <a href={getGlobalLink(item.productCategoryId)}>
                  <span className="font-inter text-14 font-600 leading-30 text-fnp-500 duration-200 hover:text-fnp-300">
                    {item.categoryName}
                  </span>
                </a>
              ) : (
                <span className="font-inter text-14 font-600 leading-30 text-fnp-500 duration-200 hover:text-fnp-300">
                  {item.categoryName}
                </span>
              )}
              {subChildrenCategory(item.childCategoryList)}
            </>
          )}
        </React.Fragment>
      )}
    </div>
  );

  /**
   * Renders the child menu items.
   * @returns {Array<JSX.Element>} - Array of JSX elements representing the child menu items.
   */
  return (
    <div className="absolute contents items-center justify-center">
      <section className="flex min-h-96 shadow-smoke-gray">
        {[...category, ...imageBanners].map((item, index) => {
          const isCategory = index < category.length;
          const bgColor = index % 2 === 0 ? "bg-white-900" : "bg-gray-100";
          return renderItems(item, bgColor, index, !isCategory);
        })}
      </section>
    </div>
  );
};

MenuItems.propTypes = {
  category: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        categoryName: PropTypes.string,
        productCategoryId: PropTypes.string,
        parentProductCategoryId: PropTypes.string,
        childCategoryList: PropTypes.array,
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          categoryName: PropTypes.string,
          productCategoryId: PropTypes.string,
          parentProductCategoryId: PropTypes.string,
          childCategoryList: PropTypes.array,
        }).isRequired,
      ),
    ]),
  ),
  imageBanners: PropTypes.array,
  productCategoryId: PropTypes.string,
};

export default React.memo(MenuItems);
