"use client";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import ReactDOM from "react-dom";
import dynamic from "next/dynamic";
import { requestToGetCustomerDetails } from "../../../store/slices/loginSignupSlice";
import { requestToGetWebFeatureControls } from "../../../store/slices/webFeatureControls";
import { DesktopMegaMenu } from "../megaMenu";
import FNPIcon from "./fnpIcon/index";
import HeaderToolBar from "./headerToolBar";
import { requestToGetCurrency } from "../../../store/slices/common/currencyLists";
import DesktopSearch from "../desktopSearch";
import { initiateFus } from "../../../store/slices/common/fus";
import { requestToFetchFnpCashBalance, requestToFetchFnpLabel } from "../../../store/slices/fnpCash";
import isEmpty from "lodash.isempty";
import { THRESHOLD_HEADER_FOR_SEARCH } from "../../../constants/common/common";
import { setGA4LoginAndSignUp } from "../../../analytics/gaEvents";
import useAnalytics from "../../../analytics/useAnalytics";
import { GA4_EVENTS, SOCIAL_LOGIN_KEY } from "../../../analytics/gaConstant";
import { cleverTapCommonEvents } from "../../../analytics/clevertapConstants";
import { getSessionStorage, removeSessionStorage } from "../../../utils/storage/sessionStorageUtil";
import { onClevertapPublish } from "../../../api/common";
import LocationLockFallback from "../../molecules/locationLockFallback";

const LoginSignupDrawer = dynamic(() => import("../../../containers/LoginSignupPage/LoginSignupDrawer"), {
  ssr: false,
});
const LocationLockHeader = dynamic(() => import("./locationLockHeader"), {
  ssr: false,
  loading: () => <LocationLockFallback />,
});

/**
 * Header component represents the header section of the page.
 * It includes the FNPIcon, LocationLock, SearchBar, and HeaderButtons components.
 *
 * @param {Object} props - The props for the Header component.
 * @param {boolean} props.isFromPDP - Indicates if the header is being rendered on the product details page.
 * @param {boolean} props.isMobile - Indicates if the current device is mobile.
 * @param {string} props.catalogId - The catalog identifier (e.g., "india").
 * @param {Array} props.preloadLinks - Array of link objects for preloading resources.
 * @returns {JSX.Element} The JSX representation of the Header component.
 */
const Header = ({
  isFromPDP = false,
  isMobile,
  catalogId = "india",
  // preloadLinks,
  isLoginPage = false,
  serverSelectedCurrency,
  searchQuery,
  isShowSearchIcon = true,
  menuData,
}) => {
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const { trackEvent, onUserLogin } = useAnalytics();
  const [isSearchIconVisible, setIsSearchIconVisible] = useState(false);
  const [isAddShadow, setIsAddShadow] = useState(false);
  const { loginSignUp, loginDetail, checkLoginStatus } = useSelector(
    (state) => ({
      loginSignUp: state?.loginSignUp,
      loginDetail: state?.fus?.loginDetail,
      checkLoginStatus: state.fus.loginDetail?.loggedin,
    }),
    shallowEqual,
  );

  /**
   * On scroll handler to get the offset of documentElement.
   */
  const onHandleScroll = () => {
    const offsetTop = document.documentElement.getBoundingClientRect().top;
    const bannerHeight = document.getElementById("appBanner")?.clientHeight || 0;

    if (isMobile) {
      if (Math.abs(offsetTop) < THRESHOLD_HEADER_FOR_SEARCH + bannerHeight) {
        setIsSearchIconVisible(false);
        setIsAddShadow(false);
      } else {
        setIsSearchIconVisible(true);
        setIsAddShadow(true);
      }
    } else {
      if (Math.abs(offsetTop) > 0) {
        setIsAddShadow(true);
      } else {
        setIsAddShadow(false);
      }
    }
  };

  useEffect(() => {
    if (loginDetail?.loggedin) {
      dispatch(requestToGetCustomerDetails());
    }
  }, [loginDetail?.loggedin]);

  useEffect(() => {
    const isUserLoggedIn = loginDetail?.loggedin && !isEmpty(loginSignUp?.userDetails);
    if (isUserLoggedIn) {
      const userDetails = { ...loginSignUp?.userDetails, userType: loginDetail?.userType };
      const isOAuth = loginDetail?.isOAuthUser;
      const storageItem = !!getSessionStorage(SOCIAL_LOGIN_KEY);
      const eventName = loginDetail?.userType === "Existing" ? GA4_EVENTS.LOGIN : GA4_EVENTS.SIGNUP;
      if (storageItem) {
        const publishCleverTapData = { profile: { identity: userDetails?.emailID } };
        onClevertapPublish(publishCleverTapData);
        const properties = setGA4LoginAndSignUp(userDetails, isOAuth);
        trackEvent(eventName, { ...properties }, ["ga4"]);
        onUserLogin({
          Site: {
            Identity: userDetails?.emailID,
            Email: userDetails?.emailID,
          },
        });
        trackEvent(cleverTapCommonEvents.loginSuccess, { loginSuccess: "Login Success" }, ["clevertap"]);
        removeSessionStorage(SOCIAL_LOGIN_KEY);
      }
    }
  }, [loginSignUp?.userDetails]);

  useEffect(() => {
    dispatch(requestToGetWebFeatureControls());
    dispatch(requestToGetCurrency());
    dispatch(initiateFus());
    dispatch(requestToFetchFnpLabel());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", onHandleScroll);

    return () => {
      document.removeEventListener("scroll", onHandleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkLoginStatus) dispatch(requestToFetchFnpCashBalance());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLoginStatus]);

  // Prefetch, preload, or preconnect links based on their `rel` attribute
  // if (preloadLinks && preloadLinks.length > 0) {
  //   preloadLinks.forEach((linkItem) => {
  //     if (linkItem.rel === "dns-prefetch") {
  //       ReactDOM.prefetchDNS(linkItem.href);
  //     } else if (linkItem.rel === "preload") {
  //       ReactDOM.preload(linkItem.href);
  //     } else if (linkItem.rel === "preconnect") {
  //       ReactDOM.preconnect(linkItem.href);
  //     }
  //   });
  // }

  if (isLoginPage) {
    return (
      <header className="mx-auto my-0 flex px-40 py-12">
        <div className="flex justify-start">
          <FNPIcon imgWidth={81} imgHeight={40} />
        </div>
      </header>
    );
  }

  return (
    <>
      <div
        className={`${isMobile ? `relative border-b-1 border-lightGrey p-16` : "fixed"} bg-white top-0 z-[100] mb-8 grid w-full grid-cols-1  bg-white-900  ${isAddShadow ? "shadow-[0_2px_4px_rgba(0,0,0,0.1)]" : ""} md:p-0`}
        ref={headerRef}
      >
        <header className="flex items-center justify-between md:px-40 md:py-12">
          <div className="flex w-3/5 justify-start">
            <div className="flex items-center justify-center md:gap-20">
              <span className="hidden md:flex">
                <FNPIcon />
              </span>
              <span className="border-white-smoke hidden h-48 border-r border-solid lg:flex" />
              {!isFromPDP && <LocationLockHeader isMobile={isMobile} />}
            </div>

            <span className="relative hidden w-full max-w-[652px] justify-center md:mx-40 md:flex md:flex-col">
              <DesktopSearch catalogId={catalogId} searchQuery={searchQuery} />
            </span>
          </div>
          <HeaderToolBar
            isMobile={isMobile}
            serverSelectedCurrency={serverSelectedCurrency}
            isSearchIconVisible={isSearchIconVisible && isShowSearchIcon}
            catalogId={catalogId}
          />
        </header>

        {!isMobile && (
          <>
            <DesktopMegaMenu data={menuData} classes="md:flex" />
            {loginSignUp?.openLoginDrawer ? <LoginSignupDrawer /> : null}
          </>
        )}
      </div>
    </>
  );
};
export default React.memo(Header);

// Define PropTypes for the Header component
Header.propTypes = {
  isFromPDP: PropTypes.bool,
  isMobile: PropTypes.bool,
  catalogId: PropTypes.string,
  // preloadLinks: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     rel: PropTypes.string.isRequired,
  //     href: PropTypes.string.isRequired,
  //   }),
  // ),
  isLoginPage: PropTypes.bool,
  serverSelectedCurrency: PropTypes.object,
  searchQuery: PropTypes.string,
  isShowSearchIcon: PropTypes.bool,
  menuData: PropTypes.array,
};
