/* eslint-disable react/jsx-handler-names */
import PropType from "prop-types";
import Link from "next/link";
import { useRouter } from "next/navigation";
import PopOver from "../../../../atoms/popOver";
import CustomImage from "../../../../atoms/customImage";
import { desktopIndiaConfig } from "../../../../../constants/mockData/mockData";
import { useDispatch, useSelector } from "react-redux";
import CurrencyList from "./currencyList";
import { constantText } from "../../../../../constants/common/headerToolbarConstants";
import { requestLogout, requestToOpenLoginDrawer } from "../../../../../store/slices/loginSignupSlice";
import { useState } from "react";
import MediaPopup from "../../mediaPopup";
import { LOG_OUT } from "../../../../../api/cartApi/constant";
import { lockBody } from "../../../../../utils/document";
import Chip from "../../../../atoms/chip";
import { cleverTapCommonEvents } from "../../../../../analytics/clevertapConstants";
import useAnalytics from "../../../../../analytics/useAnalytics";
import CookieUtil from "../../../../../utils/storage/cookieUtil";
import {
  LAST_VISITED_ADDRESS,
  LAST_VISITED_PINCODE,
  SELECTED_SAVED_ADDRESS,
  SELECTED_SAVED_ADDRESS_USERNAME,
} from "../../../../../constants/storage";
import PropTypes from "prop-types";

/** */
const HeaderDropDownList = ({ activeTab, setSelectedCurrency, selectedCurrency, onTabClick, commonData }) => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const router = useRouter();
  const checkLoginStatus = useSelector((state) => state.fus.loginDetail?.loggedin);
  const availableBalance = useSelector((state) => state.fnpCash?.availableBalance);
  const fnpCashTitle = useSelector((state) => state.fnpCash?.fnpCashTitle);
  const fnpChipText = useSelector((state) => state.fnpCash?.fnpChipText);
  const [isShowMedia, setIsShowMedia] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);

  const accountMenu = checkLoginStatus
    ? [...(desktopIndiaConfig?.accountDropDown?.slice(1) || []), ...(desktopIndiaConfig?.loggedInAccountDropDown || [])]
    : desktopIndiaConfig?.accountDropDown || [];

  const pageHeaderMenuData = {
    ...commonData,
    menu_name: null,
  };
  const popoverClasses = {
    moreItems:
      "absolute cursor-pointer  right-0 z-6 w-256 overflow-hidden bg-white-900 scrollbar-thin scrollbar-webkit",
    account: "absolute cursor-pointer right-0 z-6 w-208 overflow-hidden bg-white-900 scrollbar-webkit",
    currency: "absolute cursor-pointer h-[373px] w-[212px] overflow-auto bg-white-900 scrollbar-thin scrollbar-webkit",
  };
  const popoverParentClasses = {
    moreItems: "absolute top-[calc(100%+4px)] -right-16 min-w-[260px]",
    account: "absolute top-[calc(100%+4px)] -right-16 min-w-[210px]",
    currency: "absolute top-[calc(100%+4px)] -left-[85px]",
  };

  /** Handle hiding dropdown when currency is selected */
  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency); // Update the selected currency
    setIsDropdownVisible(false); // Hide the dropdown
    onTabClick(null);
  };
  /**
   * This function for handling click on dropdowns list items.
   *
   * @function
   * @param {object}
   * @returns {void}
   */
  const onDropdownItemClick = (item) => {
    if (item?.text === constantText.LOGIN_REGISTER) {
      trackEvent(cleverTapCommonEvents.headerMenuClicked, { ...pageHeaderMenuData, menu_name: item.text }, [
        "clevertap",
      ]);
      dispatch(requestToOpenLoginDrawer(true));
      lockBody();
    } else if (item?.text === constantText.MEDIA) {
      trackEvent(cleverTapCommonEvents.headerMenuClicked, { ...pageHeaderMenuData, menu_name: item.text }, [
        "clevertap",
      ]);
      setIsShowMedia(!isShowMedia);
    } else if (item?.text === constantText.LOGOUT) {
      trackEvent(cleverTapCommonEvents.headerMenuClicked, { ...pageHeaderMenuData, menu_name: item.text }, [
        "clevertap",
      ]);
      document.cookie = "lgpopsh=false; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie = "uscnlg=false; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      const pathOptions = {
        path: "/",
      };

      const selectedSaveAddress = CookieUtil.getCookie(SELECTED_SAVED_ADDRESS);
      if (selectedSaveAddress) {
        CookieUtil.deleteCookie(SELECTED_SAVED_ADDRESS, pathOptions);
        CookieUtil.deleteCookie(SELECTED_SAVED_ADDRESS_USERNAME, pathOptions);
        CookieUtil.deleteCookie(LAST_VISITED_PINCODE, pathOptions);
        CookieUtil.deleteCookie(LAST_VISITED_ADDRESS, pathOptions);
      }

      sessionStorage.clear();
      dispatch(requestLogout());
      router.push(LOG_OUT());
    }
    setIsDropdownVisible(false);
  };

  /**
   * Renders the popover content based on the provided items and textKey.
   *
   * @function renderPopoverContent
   * @param {Array} items - The list of items to render.
   * @param {string} textKey - The key used to determine the type of content to render.
   * @returns {JSX.Element} The rendered content for the popover.
   */
  const renderPopoverContent = (items, textKey) => {
    if (textKey === "countryCurrency") {
      return <CurrencyList setSelectedCurrency={handleCurrencySelect} selectedCurrency={selectedCurrency} />;
    }

    return items?.map((item, idx) => {
      /**
       *
       * @returns JSX for Item
       */
      const renderItemContent = () => (
        <div className="flex h-full items-center gap-4 border-b-1 px-16 duration-300 ease-in-out hover:bg-grey-100">
          <CustomImage
            src={item?.iconUrl}
            alt="dropdown-icons"
            sizes="10vw"
            width={20}
            height={20}
            isPriority={false}
          />
          {item.text === "fnpCash" ? (
            <div className="flex w-full items-center ">
              <span className="text-xs">{fnpCashTitle}</span>
              {checkLoginStatus ? <span className="ml-4 text-xs ">₹{availableBalance}</span> : null}
              <Chip color="error" content={fnpChipText} size="small" classes="ml-4 px-6 py-2" />
            </div>
          ) : (
            <span className="text-xs">{item[textKey]}</span>
          )}
        </div>
      );

      return item?.landingUrl ? (
        <Link
          href={item?.landingUrl}
          key={idx}
          className="flex h-48 flex-col justify-center"
          onClick={() => {
            trackEvent(cleverTapCommonEvents.headerMenuClicked, { ...pageHeaderMenuData, menu_name: item.text }, [
              "clevertap",
            ]);
          }}
        >
          {renderItemContent()}
        </Link>
      ) : (
        <div key={idx} className="flex h-48 flex-col justify-center" onClick={() => onDropdownItemClick(item)}>
          {renderItemContent()}
        </div>
      );
    });
  };

  const popoverContent = {
    moreItems: renderPopoverContent(desktopIndiaConfig?.moreInfoDropDown, "text"),
    account: renderPopoverContent(accountMenu, "text"),
    currency: renderPopoverContent(null, "countryCurrency"),
  };

  /** */
  const onToggleMediaPopup = () => {
    setIsShowMedia(!isShowMedia);
  };
  return isShowMedia ? (
    <MediaPopup onToggleMediaPopup={onToggleMediaPopup} />
  ) : (
    isDropdownVisible && (
      <PopOver
        isVisible={true}
        isShowPageScroll={true}
        classes={popoverClasses[activeTab]}
        parentClass={popoverParentClasses[activeTab]}
      >
        {popoverContent[activeTab]}
      </PopOver>
    )
  );
};
HeaderDropDownList.propTypes = {
  activeTab: PropType.string.isRequired,
  setSelectedCurrency: PropType.func.isRequired,
  selectedCurrency: PropType.string.isRequired,
  onTabClick: PropType.func.isRequired,
  commonData: PropTypes.object,
};
export default HeaderDropDownList;
