import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { CART, CHANGE_CURRENCY, CURRENCY_ID } from "../../../../../constants/common/common";
import { mobileIcons, mobileIconsHome } from "../../../../../constants/common/headerToolbarConstants";
import IconButtonComponent from "../../../../atoms/iconButton";
import CurrencyList from "./currencyList";
import Drawer from "../../../../molecules/drawer";
import Typography from "../../../../atoms/typography";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { requestViewCart, setIsCartVisible } from "../../../../../store/slices/cartSlice";
import { unlockBody } from "../../../../../utils/document";
import useAnalytics from "../../../../../analytics/useAnalytics";
import { cleverTapCommonEvents } from "../../../../../analytics/clevertapConstants";
import { iconsForSelectedCurrency } from "../../../../../utils/currency";
import { usePathname } from "next/navigation";
import { pageName } from "../../../../../utils/common";
import { setIsMobileSearchVisibleAction } from "../../../../../store/slices/commonSlice";

/**
 * MobileToolBar component renders a toolbar specifically designed for mobile devices.
 * It provides options for accessing cart and currency settings. The component manages
 * the display of the cart count and allows users to select their preferred currency.
 *
 * It fetches the user's selected currency from the Redux store and updates the UI accordingly.
 * Additionally, it allows users to switch between different options by clicking on various tabs.
 * If the cart tab is selected, it triggers actions to view the cart, make it visible, and reset delivery details.
 *
 * The currency selection option opens a dropdown where users can pick their preferred currency, and
 * it automatically hides once a selection is made.
 *
 * @file MobileToolBar.js
 * @module MobileToolBar
 * @requires PropType
 * @requires useCallback
 * @requires useEffect
 * @requires useState
 * @requires constants/common/common
 * @requires constants/common/headerToolbarConstants
 * @requires IconButtonComponent
 * @requires CurrencyList
 * @requires Drawer
 * @requires Typography
 * @requires useDispatch
 * @requires useSelector
 * @requires store/slices/cartSlice
 * @requires store/slices/common/shippingDateAndSlotSelection
 * @requires utils/document
 */

/**
 * MobileToolBar component renders a toolbar specifically designed for mobile devices.
 * It provides options for accessing cart and currency settings. The component manages
 * the display of the cart count and allows users to select their preferred currency.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.cartCount - The current count of items in the cart.
 * @returns {JSX.Element} The MobileToolBar component.
 */
const MobileToolBar = ({ cartCount, isSearchIconVisible = false }) => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_ID);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const userSelectedCurrency = useSelector((state) => state?.currencyList?.userSelectedCurrency);
  const { product } = useSelector(
    (state) => ({
      product: state?.pdp?.clientCurrentClickedItem,
    }),
    shallowEqual,
  );
  const urlIdentifier = product?.urlIdentifier;
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");

  const commonData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    menu_name: null,
  };

  useEffect(() => {
    if (userSelectedCurrency?.currency?.id) {
      setSelectedCurrency(userSelectedCurrency.currency.id);
    }
  }, [userSelectedCurrency]);

  /**
   * This function handles the tab click based on the provided tab name.
   * When the "cart" tab is clicked, it dispatches actions to view the cart,
   * make it visible, and reset delivery details.
   *
   * @function
   * @param {string} tabName - The name of the tab to handle click events for.
   * @returns {void}
   */
  const onTabClick = useCallback(
    (tabName) => {
      if (tabName) {
        trackEvent(cleverTapCommonEvents.headerMenuClicked, { ...commonData, menu_name: tabName }, ["clevertap"]);
      }
      switch (tabName) {
        case "cart":
          cartCount > 0 && dispatch(requestViewCart());
          dispatch(setIsCartVisible(true));
          break;
        case "search":
          dispatch(setIsMobileSearchVisibleAction(true));
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartCount],
  );

  /** */
  const setIconSrc = (url, id) => {
    if (selectedCurrency && id === "currency") {
      return iconsForSelectedCurrency(selectedCurrency);
    }
    return url;
  };

  /**
   * Handles hiding the currency dropdown when a currency is selected,
   * and updates the selected currency.
   *
   * @param {string} currency - The selected currency ID.
   * @returns {void}
   */
  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency); // Update the selected currency
    setIsDropdownVisible(false); // Hide the dropdown
    unlockBody();
    onTabClick(null);
  };

  /**
   * Handles click events on different tabs.
   * Opens the currency dropdown when the "currency" tab is clicked.
   *
   * @param {string} id - The ID of the clicked tab.
   * @returns {void}
   */
  const onHandleClick = (id) => {
    onTabClick(id);
    if (id === "currency") {
      setIsDropdownVisible(true);
    }
    trackEvent(cleverTapCommonEvents.headerMenuClicked, { ...commonData, menu_name: id }, ["clevertap"]);
  };

  /**
   * Handles closing of the currency dropdown drawer.
   *
   * @returns {void}
   */
  const onDrawerClose = () => {
    setIsDropdownVisible(false);
  };

  /**
   *
   * @returns Rendering of mobile icons
   */
  const renderMobileIcons = (item, index) => (
    <div
      key={index}
      className={`relative flex cursor-pointer items-center justify-center `}
      tabIndex="0"
      id={item?.id}
      onClick={() => onHandleClick(item?.id)}
    >
      {item?.label === CART && cartCount > 0 && (
        <span className="absolute bottom-13 left-20 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-r from-notification-secondary to-notification-primary p-4 text-10 font-500 text-white-900">
          {cartCount}
        </span>
      )}
      <IconButtonComponent
        labelColor="w-full font-normal"
        classes="py-2 flex flex-col justify-center items-center gap-2"
        imgWidth={item?.width}
        iconSrc={setIconSrc(item?.url, item?.id)}
        isPriority={false}
      />
    </div>
  );

  return (
    <>
      {isSearchIconVisible && renderMobileIcons(mobileIcons?.[0], 0)}
      {mobileIconsHome?.map((item, index) => renderMobileIcons(item, index))}
      <Drawer
        position="bottom"
        isOpen={isDropdownVisible}
        onDrawerClose={onDrawerClose}
        styles="px-16 max-h-[90vh] pb-0"
      >
        <div className="flex flex-col gap-8">
          <Typography Tag={"h4"} variant="h6" classes="text-16 font-600 mb-8">
            {CHANGE_CURRENCY}
          </Typography>
          <div className="mb-16 max-h-[calc(70vh-34px)] overflow-auto rounded-8 border-1 border-thinLightGrey">
            <CurrencyList setSelectedCurrency={handleCurrencySelect} selectedCurrency={selectedCurrency} />
          </div>
        </div>
      </Drawer>
    </>
  );
};

MobileToolBar.propTypes = {
  cartCount: PropTypes.number,
  isSearchIconVisible: PropTypes.bool,
};

export default MobileToolBar;
