import React from "react";
import PropTypes from "prop-types";

/**
 * A customizable Skeleton component for loading states.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.animation="pulse"] - The animation type. Options: 'pulse', 'wave', or false to disable.
 * @param {string} [props] - The shape of the skeleton. Options: 'text', 'rectangular', 'rounded', 'circular'.
 * @param {Object} [props.customStyle] - Custom styles for the skeleton (replaces width and height).
 * @param {React.ReactNode} [props.children] - Optional children to infer width and height from.
 * @param {number} [props.count=1] - The number of skeletons to render.
 * @param {string} [props.gap="8px"] - The gap between skeletons when count > 1.
 * @param {string} [props.alignment="flex-start"] - The alignment of skeletons. Options: 'flex-start', 'center', 'flex-end'.
 * @returns {React.ReactElement} The Skeleton component.
 */
const Skeleton = ({
  animation = "pulse",
  variant,
  customStyle = {},
  children,
  count = 1,
  gap = "8px",
  alignment = "flex-start",
  ...props
}) => {
  const baseClasses = "bg-gray-300 rounded";

  const variantClasses = {
    rectangular: "rounded-none",
    rounded: "rounded-lg",
    circular: "rounded-full",
  }[variant];

  const animationClasses = {
    pulse: "animate-pulse",
    wave: "bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 bg-[length:200%_100%] animate-wave",
    false: "",
  }[animation];

  const skeletonClasses = `${baseClasses} ${variantClasses} ${animationClasses}`;

  const skeletons = Array.from({ length: count }, (_, index) => (
    <div
      key={index}
      className={skeletonClasses}
      style={{ ...customStyle, marginRight: index < count - 1 ? gap : 0 }}
      {...props}
    >
      {children}
    </div>
  ));

  return <div style={{ display: "flex", alignItems: alignment }}>{skeletons}</div>;
};

Skeleton.propTypes = {
  animation: PropTypes.oneOf(["pulse", "wave", false]),
  variant: PropTypes.oneOf(["rectangular", "rounded", "circular"]),
  customStyle: PropTypes.object,
  component: PropTypes.elementType,
  children: PropTypes.node,
  count: PropTypes.number,
  gap: PropTypes.string,
  alignment: PropTypes.oneOf(["flex-start", "center", "flex-end"]),
};

export default Skeleton;
