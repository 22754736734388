/**
 * A loading fallback component for the Location Lock Header.
 * It displays a placeholder UI while the actual location data is loading.
 *
 * @component
 * @example
 * return <LocationLockFallback />;
 */
const LocationLockFallback = () => {
  return (
    <div className="flex w-full min-w-[150px] items-center gap-8 md:max-w-[250px]">
      <span className="block h-[24px] w-[24px] animate-pulse ">
        <div className="h-full w-full rounded bg-grey-300" />
      </span>
      <div className="flex flex-col gap-8">
        <span className="block h-[20px] w-[140px] animate-pulse md:w-[225px]">
          <div className="h-full w-full rounded bg-grey-300" />
        </span>

        <span className="block h-[16px] w-[100px] animate-pulse md:w-[150px]">
          <div className="h-full w-full rounded bg-grey-300" />
        </span>
      </div>
    </div>
  );
};

export default LocationLockFallback;
