import PropTypes from "prop-types";
import DesktopToolBar from "./headerComponents/desktopToolBar";
import MobileToolBar from "./headerComponents/mobileToolBar";
import { useSelector } from "react-redux";
import Cart from "../../cart";

/**
 * HeaderToolBar component that displays a responsive toolbar based on the screen size.
 * It conditionally renders either the DesktopToolBar or MobileToolBar
 * and manages the visibility of the cart based on the contents of the cart.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.isMobile - Indicates whether the device is mobile or not.
 * @returns {JSX.Element} The rendered component.
 */
const HeaderToolBar = ({ isMobile, serverSelectedCurrency, isSearchIconVisible = false, catalogId }) => {
  const cartCount = useSelector((state) => state.cart?.cartCount);

  return (
    <>
      <div className="flex items-center justify-center gap-16 text-12 xxl:gap-20" id="header-tool-bar">
        {isMobile ? (
          <MobileToolBar cartCount={cartCount} isSearchIconVisible={isSearchIconVisible} />
        ) : (
          <DesktopToolBar cartCount={cartCount} serverSelectedCurrency={serverSelectedCurrency} catalogId={catalogId} />
        )}
      </div>
      <Cart isMobile={isMobile} />
    </>
  );
};
HeaderToolBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  serverSelectedCurrency: PropTypes.object,
  isSearchIconVisible: PropTypes.bool,
  catalogId: PropTypes.string,
};
export default HeaderToolBar;
