import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import dynamic from "next/dynamic";
import SearchBar from "../header/searchBar";
import { fetchSearchSuggestions } from "../../../api/common";
import { debounce, pageName } from "../../../utils/common";
import { plpPageConstants } from "../../../constants/pageConstants/plpConstants";
import {
  CURRENCY_ID,
  DEBOUNCE_TIME,
  MAX_COUNT_RECENT_SEARCH,
  RECENT_SEARCH_LIST,
} from "../../../constants/common/common";
import { usePathname } from "next/navigation";
import { getProductListingDetailsAPI } from "../../../api/plpApi";
import { getLocalStorageItem, setLocalStorageItem } from "../../../utils/storage/localStorageUtil";
import { cleverTapCommonEvents } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import isEmpty from "lodash.isempty";
import { shallowEqual, useSelector } from "react-redux";
import { GA4_EVENTS } from "../../../analytics/gaConstant";

const Loader = dynamic(() => import("../../atoms/loader"), { ssr: false });
const Suggestions = dynamic(() => import("./searchComponents/suggestions"), { ssr: false });

/**
 * DesktopSearch component handles the search bar, search suggestions,
 * and recent searches for the desktop view.
 * It manages user input, debounces API calls, and updates localStorage for recent searches.
 *
 * @component
 */
const DesktopSearch = ({ catalogId, searchQuery }) => {
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const [inputValue, setInputValue] = useState(searchQuery || "");
  const [searchSuggestion, setSearchSuggestion] = useState(null);
  const [recentSearch, setRecentSearch] = useState([]);
  const [recentSearchCount, setRecentSearchCount] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const { product } = useSelector(
    (state) => ({
      product: state?.pdp?.clientCurrentClickedItem,
    }),
    shallowEqual,
  );
  const urlIdentifier = product?.urlIdentifier;

  // Load recent searches from localStorage on mount
  useEffect(() => {
    const searchHistory = getLocalStorageItem(RECENT_SEARCH_LIST);
    const searchHistoryRecentSearchList = searchHistory && JSON.parse(searchHistory);
    searchHistory && setRecentSearch(searchHistoryRecentSearchList?.recentSearchList);
  }, []);

  // Debounced version of the search function to fetch suggestions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestions = useCallback(
    debounce(async (value) => {
      if (value.trim().length === 0) return; // Skip API call if the input is empty
      const response = await fetchSearchSuggestions(catalogId, value, 1234);
      response?.suggestionResponse && setSearchSuggestion(response?.suggestionResponse);
      response?.recentSearchCount && setRecentSearchCount(response?.recentSearchCount);
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    setIsLoading(false);
    setSearchSuggestion(null);
  }, [searchQuery]);

  /**
   * Handles search input changes and triggers the suggestion fetch.
   *
   * @param {Event} event - The input change event.
   */
  const onSearch = async (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value.length > 0) {
      debouncedFetchSuggestions(value);
    } else {
      debouncedFetchSuggestions(value);
      setSearchSuggestion(null);
    }
  };
  /**
   * Clears the current search input and suggestions.
   */
  const onClearSearch = () => {
    setInputValue("");
    setSearchSuggestion(null);
  };
  /**
   * Handles focus on the input field.
   */
  const onFocus = () => {
    setIsFocused(true);
    const pageHeaderMenuData = {
      page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
      pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
      menu_name: "Search",
    };
    trackEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData, ["clevertap"]);
  };

  /**
   * Handles blur (focus out) on the input field.
   */
  const onBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };

  /**
   * Adds a search keyword to the recent search list in localStorage.
   *
   * @param {string} keyword - The search keyword.
   * @param {string} url - The URL associated with the search keyword.
   */
  const addRecentSearchKeyword = (keyword, url) => {
    const searchHistory = getLocalStorageItem(RECENT_SEARCH_LIST);
    let recentSearchList = searchHistory ? JSON.parse(searchHistory) : { recentSearchList: [] };
    // Remove the existing object with the same keyword and URL, if it exists
    recentSearchList.recentSearchList = recentSearchList.recentSearchList.filter(
      (item) => item.keyword !== keyword || item.url !== url,
    );
    // Check if the list exceeds 8 items, remove the oldest one
    if (recentSearchList.recentSearchList.length >= MAX_COUNT_RECENT_SEARCH) {
      recentSearchList.recentSearchList.shift();
    }
    // Add the new search entry
    recentSearchList.recentSearchList.push({
      keyword: keyword,
      url: url,
      suggestionResponseType: "RS",
    });
    // Update localStorage
    setLocalStorageItem(RECENT_SEARCH_LIST, JSON.stringify(recentSearchList));
  };

  /**
   * Handles the form submission for searching and redirects to the product listing page.
   *
   * @param {Event} e - The form submit event.
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    if (inputValue === searchQuery || isEmpty(inputValue)) {
      return;
    }

    setIsLoading(true);

    const pageListingData = {
      page: plpPageConstants.INITIAL_PAGE,
      size: plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
      geoId: catalogId,
      lang: "en",
      currency: CURRENCY_ID,
      domainId: "fnp.com",
      isFacetEnabled: false,
      quickFilter: false,
      isFromApp: "yes",
      pageType: "search",
      qs: inputValue,
    };
    const res = await getProductListingDetailsAPI(pageListingData);
    if (res?.redirectUrl) {
      const url = new URL(res.redirectUrl);
      const relativePath = `${url?.pathname}${url?.search}`;
      addRecentSearchKeyword(inputValue, res.redirectUrl);
      window.location.href = relativePath;
    } else {
      addRecentSearchKeyword(inputValue, `/search?FNP_CURRENT_CATALOG_ID=${catalogId}&qs=${inputValue}`);
      window.location.href = `/search?FNP_CURRENT_CATALOG_ID=${catalogId}&qs=${inputValue}`;
    }

    const clevertapSearchData = {
      search: inputValue,
      is_popular_search: false,
      is_recent_search: false,
      is_manual_boost: false,
    };
    trackEvent(cleverTapCommonEvents.searched, clevertapSearchData, ["clevertap"]);
    trackEvent(GA4_EVENTS?.SEARCH, { search: inputValue || null }, ["ga4"]);
  };

  const combinedSearch = [
    ...(Array.isArray(recentSearch) ? recentSearch.slice(-recentSearchCount) : []),
    ...(Array.isArray(searchSuggestion) ? searchSuggestion : []),
  ];

  return (
    <div>
      {isLoading && <Loader />}
      <form className="w-full" onSubmit={onSubmit}>
        <SearchBar
          isShowStartAdornment={true}
          inputValue={inputValue}
          onChange={onSearch}
          onClearSearch={onClearSearch}
          isShowEndAdornment={true}
          isMobile={false}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </form>
      {combinedSearch?.length > 0 && isFocused && <Suggestions searchSuggestion={combinedSearch} />}
    </div>
  );
};
DesktopSearch.propTypes = {
  catalogId: PropTypes.string,
  searchQuery: PropTypes.string,
};
export default DesktopSearch;
