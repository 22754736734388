import PropTypes from "prop-types";
import TextField from "../../../../components/atoms/textField/index";
import CustomImage from "../../../atoms/customImage";
import ImageTile from "../../../atoms/imageTile";
import { SEARCH_ANIMATION_SPEED, SEARCH_BAR_PLACEHOLDER } from "../../../../constants/common/common";
import Regex from "../../../../utils/regex";
import { useEffect, useRef, useState } from "react";
import { getDynamicSearchTextPlaceholder } from "../../../../api/common";
import isEmpty from "lodash.isempty";
import useAnalytics from "../../../../analytics/useAnalytics";
import { pageName } from "../../../../utils/common";
import { usePathname } from "next/navigation";
import { shallowEqual, useSelector } from "react-redux";
import { cleverTapCommonEvents } from "../../../../analytics/clevertapConstants";
/**
 * SearchBar component represents the search bar in the header section.
 * @returns {JSX.Element} The JSX representation of the SearchBar component.
 */
const SearchBar = ({
  isShowStartAdornment = false,
  isShowEndAdornment = false,
  classes = "",
  inputValue = "",
  onChange,
  onClearSearch,
  isReadonly = false,
  isMobile = false,
  onFocus: onFocusTextField = () => {},
  onBlur = () => {},
  onSearchBarClick = () => {},
  onMicClick = () => {},
  isAutoFocus = false,
}) => {
  const [dynamicPlaceholderTexts, setDynamicPlaceholderTexts] = useState([]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const [isTyping, setIsTyping] = useState(true);

  const { product } = useSelector(
    (state) => ({
      product: state?.pdp?.clientCurrentClickedItem,
    }),
    shallowEqual,
  );
  const urlIdentifier = product?.urlIdentifier;
  const pathname = usePathname();
  const interval = useRef(null);
  const { trackEvent } = useAnalytics();
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  /**
   * This function fetches dynamic placeholder for search bar
   * @async
   * @function fetchDynamicPlaceholder
   */
  const fetchDynamicPlaceholder = async () => {
    const dynamicPlaceholder = await getDynamicSearchTextPlaceholder(); // TBD: Saving the API response in localStorage with dataLayer
    setDynamicPlaceholderTexts(dynamicPlaceholder?.searchTerm || []);
  };

  useEffect(() => {
    fetchDynamicPlaceholder();
  }, []);

  useEffect(() => {
    if (!isEmpty(dynamicPlaceholderTexts) && !isAutoFocus) {
      const currentFullText = dynamicPlaceholderTexts[currentTextIndex];

      interval.current = setInterval(() => {
        if (isTyping) {
          setDisplayText((prev) => {
            if (prev.length < currentFullText?.length) {
              return currentFullText.slice(0, prev.length + 1);
            } else {
              if (currentTextIndex >= dynamicPlaceholderTexts.length) {
                // Stop cycling through texts
                clearInterval(interval.current);
                return SEARCH_BAR_PLACEHOLDER;
              } else {
                setIsTyping(false);
              }
              return prev; // Stop typing when full text is reached
            }
          });
        } else {
          setDisplayText((prev) => {
            if (prev.length > 0) {
              return prev.slice(0, prev.length - 1);
            } else {
              setIsTyping(true);
              setCurrentTextIndex((prevIndex) => prevIndex + 1);
              return prev; // Start typing again
            }
          });
        }
      }, SEARCH_ANIMATION_SPEED);
    }
    return () => clearInterval(interval.current); // Cleanup on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping, currentTextIndex, dynamicPlaceholderTexts]);

  /**
   * Handles the change event for the input field.
   * Validates the input against the alphabet regex before updating the value.
   *
   * @param {Event} event - The input change event.
   */
  const onHandleInputChange = (event) => {
    const value = event.target.value;
    // Check if the input matches the regex
    if (Regex.alphaNumericWithSpace.test(value)) {
      onChange(event); // Call the onChange prop if valid
    }
  };

  /**
   * Handles focus event on search text fields
   * @param {object} event
   */
  const onFocusSearchTextField = (e) => {
    clearInterval(interval.current);
    setDisplayText(SEARCH_BAR_PLACEHOLDER);
    onFocusTextField(e);
    const pageHeaderMenuData = {
      page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
      pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
      menu_name: "Search",
    };
    trackEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData, ["clevertap"]);
  };

  return (
    <div className={`${classes}  w-full`}>
      <TextField
        isRequired={false}
        readOnly={isReadonly}
        type="text"
        id="search_bar"
        classes={`placeholder:font-normal placeholder-grey-500 text-14 leading-20 font-medium rounded-lg border-gray-300 bg-white-100 focus:bg-white-900`}
        variant="primary"
        placeholder={displayText}
        hasEndDivider={inputValue.length > 0 ? false : true}
        autoFocus={isAutoFocus}
        inputValue={inputValue}
        onChange={onHandleInputChange}
        onClick={onSearchBarClick}
        onFocus={onFocusSearchTextField}
        onBlur={onBlur}
        startAdornment={
          isShowStartAdornment && (
            <ImageTile
              imgSrc="/icons/search-bar.svg"
              imgAlt="search-bar-icon"
              imgWidth={20}
              imgHeight={20}
              isPriority={false}
            />
          )
        }
        tabIndex="0"
        endAdornment={
          isShowEndAdornment &&
          (inputValue.length > 0 ? (
            <CustomImage
              src="/icons/cross-circle.svg"
              width={20}
              height={20}
              isPriority={false}
              sizes="10vw"
              alt="search icons"
              onClick={onClearSearch}
            />
          ) : (
            isMobile && (
              <CustomImage
                src="/icons/microphone.svg"
                width={24}
                height={24}
                isPriority={false}
                sizes="10vw"
                alt="search icons"
                onClick={onMicClick}
              />
            )
          ))
        }
      />
    </div>
  );
};
SearchBar.propTypes = {
  isAutoFocus: PropTypes.bool,
  isShowStartAdornment: PropTypes.bool,
  isShowEndAdornment: PropTypes.bool,
  classes: PropTypes.string,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  onClearSearch: PropTypes.func,
  isReadonly: PropTypes.bool,
  isMobile: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onSearchBarClick: PropTypes.func,
  onMicClick: PropTypes.func,
};
export default SearchBar;
