import React from "react";
import Skeleton from "../skeleton"; // Import the Skeleton component

/**
 *
 * @returns Skeleton for Cart
 */
const CartSkeleton = () => {
  return (
    <div className="w-full p-12">
      <Skeleton customStyle={{ width: "100%", height: "36px", marginBottom: "12px" }} />
      <div className="flex justify-between">
        <Skeleton variant="rounded" customStyle={{ width: "80px", height: "80px", marginBottom: "16px" }} />
        <div className="ml-12 w-full">
          <Skeleton customStyle={{ width: "100%", height: "16px", marginBottom: "12px" }} />
          <Skeleton customStyle={{ width: "100%", height: "16px", marginBottom: "12px" }} />
          <Skeleton variant="circular" customStyle={{ width: "32px", height: "32px", marginBottom: "12px" }} />
        </div>
      </div>
      <Skeleton variant="rectangular" customStyle={{ width: "100%", height: "40px" }} />
    </div>
  );
};

export default CartSkeleton;
